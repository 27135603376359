import { createRouter, createWebHashHistory } from "vue-router";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("../views/Newboard.vue"),
  },
  {
    path: "/newboard",
    name: "newboard",
    component: () => import("../views/Newboard.vue"),
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: () => import("../views/Inventory/List-Inventory.vue"),
  },
  {
    path: "/report",
    name: "Report List",
    component: () => import("../views/Receipt/newReport.vue"),
  },
  {
    path: "/scan",
    name: "Scan",
    children: [
      {
        path: "",
        name: "List",
        component: () => import("../views/Scan/ScanListNew.vue"),
      },
      {
        path: "detail",
        name: "Outbound",
        component: () => import("../views/Scan/ScanDetailNew.vue"),
      },
      {
        path: "manual",
        name: "Manual via Excell",
        component: () => import("../views/Scan/ScanDetailManual.vue"),
      },
      {
        path: "retur",
        name: "Retur Keluar",
        component: () => import("../views/Scan/ScanDetailNew.vue"),
      },
      {
        path: "merge",
        name: "another",
        component: () => import("../views/Scan/ScanMerge.vue"),
      },
    ],
  },
  {
    path: "/retur",
    name: "Barang Retur",
    children: [
      {
        path: "",
        name: "List Retur",
        component: () => import("../views/Retur/List-Retur.vue"),
      },
      {
        path: "form",
        name: "Form Retur",
        component: () => import("../views/Retur/Form-Retur.vue"),
      },
      {
        path: "in",
        name: "Barang Retur Masuk",
        component: () => import("../views/Retur/ScanRetur.vue"),
      },
      {
        path: "out",
        name: "Barang Retur Keluar",
        component: () => import("../views/Retur/ScanRetur.vue"),
      },
      {
        path: "process",
        name: "Proses Barang Retur",
        component: () => import("../views/Retur/ProcessRetur.vue"),
      },
      {
        path: "set-shipment",
        name: "Atur Pengiriman Barang Retur",
        component: () => import("../views/Retur/SetShipment.vue"),
      },
    ],
  },
  {
    path: "/loan",
    name: "Loan",
    children: [
      {
        path: "",
        name: "Loan List",
        component: () => import("../views/Loan/Loan.vue"),
      },
      {
        path: "detail",
        name: "Loan Detail",
        component: () => import("../views/Loan/LoanDetail.vue"),
      },
    ],
  },
  {
    path: "/outgoing",
    name: "Outgoing",
    children: [
      {
        path: "",
        name: "Outgoing List",
        component: () => import("../views/Outgoing/OutgoingList.vue"),
      },
      {
        path: "form",
        name: "Outgoing Form",
        component: () => import("../views/Outgoing/OutgoingForm.vue"),
      },
      {
        path: "detail/:id",
        name: "Outgoing Detail",
        component: () => import("../views/Outgoing/OutgoingDetail.vue"),
      },
      {
        path: "faktur/:id",
        name: "Outgoing Faktur",
        component: () => import("../views/Outgoing/OutgoingFaktur.vue"),
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    children: [
      {
        path: "",
        name: "Setting List",
        component: () => import("../views/Settings/SettingsMenu.vue"),
      },
      {
        path: "old",
        name: "Setting Old",
        component: () => import("../views/Settings/Settings.vue"),
      },
      {
        path: "shipment",
        name: "Manage Shipment",
        component: () => import("../views/Settings/ManageEkspedisi.vue"),
      },
      {
        path: "color",
        name: "Color List",
        component: () => import("../views/Settings/SettingsColor.vue"),
      },
    ],
  },
  {
    path: "/inbound",
    name: "Inbound",
    children: [
      {
        path: "",
        name: "Inbound List",
        component: () => import("../views/Inbound/List-Inbound.vue"),
      },
      {
        path: "detail/:id_inbound?",
        name: "Inbound Detail",
        component: () => import("../views/Inbound/Form-Inbound.vue"),
      },
      {
        path: "mapping/:id_inbound?",
        name: "Mapping Inbound",
        component: () => import("../views/Inbound/Form-Mapping-Inbound.vue"),
      },
      {
        path: "list-mapping",
        name: "Mapping List",
        component: () => import("../views/Inbound/List-Mapping.vue"),
      },
      {
        path: "mapping/detail/:id_inbound",
        name: "Mapping Detail",
        component: () => import("../views/Inbound/Mapping-Detail.vue"),
      },
      {
        path: "mapping/print/:id_inbound",
        name: "Mapping Print",
        component: () => import("../views/Inbound/Mapping-Print.vue"),
      },
    ],
  },
  {
    path: "/stock",
    children: [
      {
        path: "",
        name: "List Stok",
        component: () => import("../views/Stok/List-Stok.vue"),
      },
      {
        path: "form",
        name: "Form Penyesuaian",
        component: () => import("../views/Stok/Form-Stock.vue"),
      },
      {
        path: "history/:sku",
        name: "Riwayat Stok",
        component: () => import("../views/Stok/Riwayat-Stok.vue"),
      },
      {
        path: "adjustment",
        name: "Riwayat Penyesuaian Stok",
        component: () => import("../views/Stok/List-Adjustment-Stok.vue"),
      },
      {
        path: "adjustment/detail/:id_unix",
        name: "Detail Penyesuaian Stok",
        component: () => import("../views/Stok/Detail-Adjustment-Stok.vue"),
      },
      {
        path: "reminder",
        name: "Pengingat Stok",
        component: () => import("../views/Stok/Pengingat-Stok.vue"),
      },
    ],
  },
  {
    path: "/sinkronisasi",
    children: [
      {
        path: "",
        name: "List Sinkronisasi",
        component: () => import("../views/Sinkronisasi/List-Sinkronisasi.vue"),
      },
      {
        path: "detail/:id_sinkronisasi",
        name: "Detail Sinkronisasi",
        component: () =>
          import("../views/Sinkronisasi/Detail-Sinkronisasi.vue"),
      },
    ],
  },
  {
    path: "/stock-sync",
    children: [
      {
        path: "",
        name: "List Sync Stock",
        component: () => import("../views/Sync-Stock/List-Sync-Stock.vue"),
      },
      {
        path: "form",
        name: "Form Sync Stock",
        component: () => import("../views/Sync-Stock/Form-Sync-Stock.vue"),
      },
      {
        path: "detail/:id",
        name: "Detail Sync Stock",
        component: () => import("../views/Sync-Stock/Detail-Sync-Stock.vue"),
      },
    ],
  },
  {
    path: "/analisa",
    children: [
      {
        path: "",
        name: "Analisa Stok",
        component: () => import("../views/Analisa/AnalisaStok.vue"),
      },
      {
        path: "poc",
        name: "Analisa POC",
        component: () => import("../views/Analisa/component-poc.vue"),
      },
    ],
  },
  {
    path: "/integrasi",
    name: "Integration",
    component: () => import("../views/Intregrasi/List-Ecommerce.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      noAuth: true,
    },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/noaccess",
    meta: {
      noAuth: true,
    },
    component: () => import("@/views/NoAccess"),
  },
  {
    path: "/:pathMatch(.*)*",
    meta: {
      noAuth: true,
    },
    component: () => import("@/views/NotFound"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let temp_token = null;
  if (process.env.NODE_ENV == "development") {
    // next();
    // ADMIN
    // temp_token =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluIiwiaWF0IjoxNzA1ODkzODc3LCJleHAiOjE3MDYxNTMwNzd9.-JHH2tzqzVoo5bkx6ROxExs3IBk3z-7knCYEefA_Qjk";
    // warehouse staff
    // temp_token =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJqYWNvYiIsIm5hbWUiOiJKYWNvYiBKb25lcyIsInJvbGUiOiJXQVJFSE9VU0UiLCJpYXQiOjE3MDM3MzU3NDQsImV4cCI6MTcwNDM0MDU0NH0.D539GrsECMfC2vsGs5uxyRMU4OwgvCXTRg3Zj2VsFi0";
    // shopee admin
    // temp_token =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwidXNlcm5hbWUiOiJzaG9wZWUiLCJuYW1lIjoiU2hvcGVlIEFkbWluIiwicm9sZSI6IkFETUlOIiwiaWF0IjoxNzAzNzM1NzY0LCJleHAiOjE3MDQzNDA1NjR9.tFEk5X2UKzumKjZ0hr9h9NF76IXiSDvebx41ErHqXKo";
    // GATEKEEPER
    // temp_token =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwidXNlcm5hbWUiOiJnYXRla2VlcGVyIiwibmFtZSI6IkdBVEVLRUVQRVIiLCJyb2xlIjoiR0FURUtFRVBFUiIsImlhdCI6MTcwMzczNTgyMCwiZXhwIjoxNzA0MzQwNjIwfQ.i8WyaYCp7_DtJLR_28hIkSlK-FganZU-ubz03EWtUGU";
    // localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, temp_token);
  }

  let token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
  // token = null;
  if (to.meta.noAuth) {
    next();
  } else if (token === null) {
    // console.log("no token");
    next("/login");
  } else {
    try {
      let resp = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/user/user_data`,
        {
          token: token,
        }
      );
      to.meta.user = resp.data;
      if (to.meta?.access == null) {
        next();
      } else {
        let access = checkAccess(resp.data, to.meta.access);
        if (access) {
          next();
        } else {
          next("/noaccess");
        }
      }
    } catch (error) {
      console.log(error);
      console.log("gagal menarik data user");
      localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
      let redirect_url = to.fullPath;
      redirect_url = encodeURIComponent(redirect_url);
      next(`/login?redirect_url=${redirect_url}`);
    }
  }
});

export default router;
